export enum AspectRatioKeys {
  NotSet = '',
  Portrait = 'aspect-portrait',
  Landscape = 'aspect-landscape',
  FullScreenPortrait = 'aspect-full-screen-portrait',
  WidescreenLandscape = 'aspect-wide',
  Square = 'aspect-square',
  UltraWide = 'aspect-ultra-wide',
  Original = 'aspect-original',
}
